<template>
    <div class="position">
        <headLine msg='岗位'></headLine> 
        <div class="topBtn">
            <el-button type="primary" size="small" @click="addposition"
            icon="el-icon-circle-plus-outline">新增岗位</el-button>
        </div>  
        <div v-if="positionList.length>0">
        <ul class="positionBox" >
            <li v-for="(v,i) in positionList" :key="i" class="positionList">
                <div class="liImg">
                    <img src="../../assets/images/position2.png"/>
                </div>
                <div class="positionInfo">
                    <h4>{{v.jobName}}</h4>
                    <div class="positionCity">
                        <span>{{v.workCity}} · 招聘{{v.recruitsNum}}人</span>
                    </div>
                    <div class="positionDetail" @click="lookPosition(v.jobIntroduce)">
                        <span class="positionText"><span class="positionTitle">岗位详情:</span> 
                        {{v.jobIntroduce}} </span>
                    </div>
                    <div class="positionPrice">
                        <span class="price">{{v.salary}}</span>
                        <span class="Btn">
                            <span class="editorBtn" title="编辑" @click="editor(v)">
                                <i class="el-icon-edit-outline"></i>
                            </span>
                            <el-popconfirm
                            title="确定删除该岗位吗？"
                            @onConfirm='delPosition(v.id)'
                            >
                                <span class="editorBtn" title="删除" slot="reference">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </el-popconfirm>
                            
                        </span>                 
                    </div>
                </div>
            </li>  
        </ul>
        <paging v-show="positionList.length>0" :totalpage="totalpage"
        @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        </div>
        <blankPage v-else msg='快来添加岗位，开启招聘第一步~'></blankPage>
        <el-dialog
            title="岗位详情"
            :visible.sync="positionModel"
            width="38%"
            >
            <div class="positionModel">   
                <ul>
                    <li v-html="detailText"></li>       
                </ul>   
            </div>         
        </el-dialog>
        <commonDialog 
        :config='config' 
        :formItemList='formItemList' 
        ref="dialog"
        @getFormItemList='getFormItemList'
        ></commonDialog>
    </div>
</template>
<script>
import {salaryList} from '../../service/config.js'
import commonDialog from '../../components/commonDialog'

export default {
    components:{
       commonDialog 
    },
    data () {
        return {
            choseId:null,
            config:{
                title:'新增岗位',
                formLabelWidth:'100px',
            },
            titleArr:['新增岗位','编辑岗位'],
            formItemList:[
                {type:'select',label:"选择岗位",value:'',key:'jobName',
                arr:[],require:true,selectlabel:'labelName',selectValue:'labelName'},
                {type:'cascader',label:"工作地点",value:'',key:'workCity',require:true},
                {type:'number',label:"招聘人数",value:'',key:'recruitsNum',require:true},
                {type:'select',label:"薪资范围",value:'',arr:salaryList,
                key:'salary',require:true,selectlabel:'',selectValue:''},
                {type:'textarea',label:"岗位要求",value:'',row:4,key:'jobIntroduce',require:true}
            ],
            salaryList,
            positionList:[],
            positionModel:false,
            compId:'',
            detailText:'' ,
            current:0,
            totalpage:0
        }
    },
    methods: {
        handle(val){
            this.current=val 
        },
        getFormItemList(arr){
            let obj={}
            let {title}=this.config
            arr.forEach(e=>{
                obj[e.key]=e.value
            })
            obj.workCity=obj.workCity.join('/')
            obj.companyId=this.compId
            
            if(title==this.titleArr[0]){
                //新增
                this.$htp.post_('/hr/job/add',obj,true).then(()=>{
                    this.$refs.dialog.show()
                    this.getInitList(this.current)       
                }) 
            }else{
                //编辑                
                obj.id=this.choseId
                this.$htp.put_('/hr/job/update',obj,true).then(()=>{
                    this.$refs.dialog.show()
                    this.getInitList(this.current)       
                }) 
            }          
        },
        editor(v){
            this.choseId=v.id
            this.formItemList.forEach(e=>{
                if (Object.prototype.hasOwnProperty.call(v, e.key)){
                    if(e.key=='workCity'){
                        e.value = v[e.key].split('/')
                    }else{
                        e.value = v[e.key]
                    }
                    
                }
            })
            this.config.title=this.titleArr[1]  
            this.$refs.dialog.show()
        },
        delPosition(id){
            this.$htp.del_('/hr/job/delete?jobId='+id,{},true).then(()=>{  
                this.getInitList(this.current)       
            }) 
        },
        addposition(){
            this.config.title=this.titleArr[0]
            this.$allFun.clearForm(this.formItemList)
            this.$refs.dialog.show()
        },
        lookPosition(detailText){
            this.detailText=detailText
            this.positionModel=!this.positionModel
        },
        getInitList(current=0){
            let url=`/hr/job/page?page=${current}&companyId=${this.compId}`
            this.$htp.get_(url).then(res=>{  
                let data=res.data  
                this.positionList=data.rows
                this.totalpage=data.total          
            }) 
        },
        getPullPosition(){
            this.$htp.get_('/hr/job/label/list').then(res=>{  
                this.formItemList[0].arr= res.data
                this.getInitList() 
            }) 
        }   
    },
    mounted(){
        this.compId=sessionStorage.getItem('id')
        this.getPullPosition()
    }
}
</script>
<style scoped lang='scss'>
@import '../../assets/css/position.scss';
</style>
<style>
 .el-dialog__header span{
    font-size: 16px;
}
 .el-dialog__body{
    padding: 20px 20px;
}
.el-dialog__header{
    border-bottom: 1px solid #EBEEF5;
}
.el-dialog__footer{
    border-top: 1px solid #EBEEF5;
}
</style>